import React from 'react';
import Layout from '@components/Layout';
import styles from './styles.module.scss';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import SEO from '@components/SEO';
import Header from '@components/Header';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';
import { format, parseISO } from 'date-fns';

const Blog = ({
    data: {
        blog: {
            html,
            frontmatter: { heading, image, description, date },
        },
        page: {
            frontmatter: { pageTitle, pageDescription },
        },
    },
}) => {
    // const regex = /\]\((.+)(?=(\.(svg|gif|png|jpe?g)))/g;
    //
    // const url = require('url');
    // const replacedText = content.toString().replace(regex, (fullResult, imagePath) => {
    //     const newImagePath = url.resolve('//localhost:8000', imagePath);
    //     return <img src={newImagePath} />;
    // });
    console.log({ date });
    return (
        <Layout>
            <SEO />
            <div className={styles.blogWrapper}>
                <div className={styles.blogContent}>
                    <div className={styles.blogImage}>
                        <Img fluid={image?.childImageSharp.fluid} />
                    </div>
                    <div className={styles.blogHeading}>
                        <h1>{heading}</h1>
                        {!!date && (
                            <p className={styles.blogHeadingLastUpdated}>
                                Last updated {format(parseISO(date), 'dd LLL yyyy HH:mm')}. <br />
                                {/*{read_time} min read.*/}
                            </p>
                        )}
                        <Divider margin={4} />
                        <p>{description}</p>

                        {/*<div className={styles.blogHeadingTags}>*/}
                        {/*    {tags?.map(({ name }) => {*/}
                        {/*        return <p className={styles.tag}>{name}</p>;*/}
                        {/*    })}*/}
                        {/*</div>*/}
                    </div>
                    <div className={styles.bodyContent}>
                        <div
                            className={styles.blogBody}
                            dangerouslySetInnerHTML={{ __html: html }}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Blog;

export const query = graphql`
    query blogTemplate($slug: String) {
        blog: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                heading: blogs_blog_title
                image: blogs_blog_overview_image {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                description: blogs_blog_overview_description
                date: blogs_blog_date
            }
        }
        page: markdownRemark(fileAbsolutePath: { regex: "/blog/" }) {
            frontmatter {
                pageTitle: blog_title
                pageDescription: blog_description
            }
        }
    }
`;
